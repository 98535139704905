* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  height: 100%;
  overflow: hidden;
  color: #ffffff;
  background-color: #121620;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
}

.app {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: background 1s ease;
}

.background-dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  background-image: 
    radial-gradient(circle at 10% 20%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 30% 80%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 50% 10%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 70% 30%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 80% 70%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 20% 40%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 60% 50%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 90% 90%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 15% 85%, rgba(255,255,255,0.1) 1px, transparent 1px),
    radial-gradient(circle at 40% 15%, rgba(255,255,255,0.1) 1px, transparent 1px);
  background-size: 100% 100%;
}

.game-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: auto;
}

.center-content {
  position: relative;
  z-index: 10;
  text-align: center;
  pointer-events: none;
}

.name {
  font-size: 4rem;
  font-weight: 200;
  letter-spacing: -1px;
  margin-bottom: 0.5rem;
  opacity: 0;
}

.name.revealed {
  opacity: 1;
  animation: revealText 1s forwards;
}

.first-name {
  color: #ffffff;
}

.last-name {
  color: rgba(255, 255, 255, 0.7);
}

.title {
  font-size: 1.1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
  opacity: 0;
}

.title.revealed {
  opacity: 1;
  animation: revealText 1s forwards;
}

@keyframes revealText {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.contact-links {
  position: absolute;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  z-index: 10;
  width: 100%;
  pointer-events: none;
}

.contact-link {
  font-size: 0.85rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  opacity: 0.4;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  pointer-events: auto;
}

.contact-link:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

.link-icon {
  font-size: 1rem;
}

.link-text {
  position: relative;
}

.link-text::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.contact-link:hover .link-text::after {
  width: 100%;
}

.cursor {
  position: fixed;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: width 0.2s, height 0.2s, background-color 0.2s;
}

.cursor::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-link:hover ~ .cursor,
.game-canvas:hover ~ .cursor {
  width: 40px;
  height: 40px;
  background-color: rgba(114, 137, 218, 0.1);
  border-color: rgba(114, 137, 218, 0.5);
  mix-blend-mode: normal;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Main container adjustments */
  .app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15vh;
    align-items: center;
    height: auto;
    min-height: 100vh;
  }

  .center-content {
    order: 1;
    width: 100%;
    padding: 0 20px;
  }

  .name {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .title {
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }

  /* Contact links - horizontal row BELOW text */
  .contact-links {
    order: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    margin: 2rem 0;
    padding: 0 1rem;
    width: 100%;
    transform: none;
    top: auto;
    bottom: auto;
    left: auto;
  }

  .contact-link {
    font-size: 0.9rem;
    opacity: 0.7;
    padding: 8px 12px;
  }

@media (max-width: 768px) and (pointer: fine) {
  /* Only show cursor for devices that actually use one (like tablets with stylus) */
  .cursor {
    display: block;
  }
}
}
/* Add to your existing CSS */
.score-display {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px 16px;
  border-radius: 20px;
  z-index: 100;
  font-weight: 500;
}

/* Mobile version */
@media (max-width: 768px) {
  .score-display {
    font-size: 1.2rem;
    padding: 6px 12px;
    top: 15px;
    right: 15px;
  }
}